import React, { useState, useEffect, useRef } from "react";
import { useAxiosSWR } from "root/axiosInstance";
import { useParams } from "react-router-dom";
import { getAssetUrl, getSanitizedArray } from "../../../../helpers/general";
import { useHorizontalScroll } from "../../../../hooks/useHorizontalScroll";
import { formatDate } from "../../../../helpers/dates";
import SEOMeta from "../../Home/common/SEOMeta";

const flightArrow = getAssetUrl("flightsmode.svg");

const SeriesTeamsV2 = () => {
    const { id: fullId } = useParams();
    const id = fullId.split('-').pop();

    const {
        data: teamData,
        error: teamsError,
        isLoading: teamsIsLoading,
        mutate: reFetchTeams
    } = useAxiosSWR(`/v1/series/${id}/teams`);
    const [selectedTeamTitle, setSelectedTeamTitle] = useState(null);
    const [selectedTeamId, setSelectedTeamId] = useState(null);
    const [seoMeta, setSEOMeta] = useState(null);

    const containerRef = useRef();
    useHorizontalScroll(containerRef, selectedTeamId);
    const {
        data: playerData,
        error: playersError,
        isLoading: loadingPlayers,
        mutate: reFetchPlayersOfTeam
    } = useAxiosSWR(selectedTeamId ? `/v1/teams/${selectedTeamId}/players` : null);

    useEffect(() => {
        if (teamData?.teams?.length > 0) {
            const firstNonTBA = teamData.teams.find(team => team.title !== 'TBA');
            if (firstNonTBA) {
                setSelectedTeamTitle(firstNonTBA.title);
                setSelectedTeamId(firstNonTBA.team_id)
            }
        }

        if (teamData?.series) {
            const seriesData = teamData.series;
            seriesData.game_format = seriesData.game_format || '';
            setSEOMeta({
                title: `${seriesData.title} ${seriesData.season} Teams and Squads | ${seriesData.game_format.toUpperCase()} | ${seriesData.abbr} | Cricketgully`,
                description: `Teams and Squads of ${seriesData.title} ${seriesData.season} scheduled on ${formatDate(seriesData.date_start, false)} to ${formatDate(seriesData.date_end, false)} | ${seriesData.game_format.toUpperCase()} | ${seriesData.abbr}`,
                canonicalURL: `/series/${seriesData.slug}/schedule`,
            });
        }
    }, [teamData]);
    
    if (teamsError) {
        return (
            <div className="text-red-500 mb-4 p-8"></div>
        )
    }

    const handleTeamClick = (title, teamId) => {
        setSelectedTeamId(teamId)
        setSelectedTeamTitle(title);
    };
    const renderPlayers = (players) => {
        return (
            <>
                {getSanitizedArray(players).map((player, index) => (
                    <div key={index} className={`md:p-0 px-3 py-4 border-t grid grid-cols-6 justify-start md:border-none md:mb-4 items-center ${index % 2 === 0 ? "border-r" : ""}`}>
                        <div className="col-span-1 rounded-full bg-[#c6c6c6] font-semibold text-white md:h-[60px] h-[38px] md:w-[60px] w-[38px] text-lg flex gap-2 justify-center items-center">
                            {player.name[0]}
                        </div>
                        <div className="col-span-5 md:ml-2 ml-[18px]">
                            <p className="flex items-center">
                                <span className="font-bold md:text-[14px] text-xs">{player.name}</span>
                                {player.is_international && (
                                    <img src={flightArrow} alt="flightArrow" className="md:ml-[100px] ml-2 opacity-50 md:h-[17px] h-[17px]" />
                                )}
                            </p>
                            <p className="font-bold text-[#929292] text-[12px]">{player.playing_role}</p>
                        </div>
                    </div>
                ))}
            </>
        );
    };

    return (
        <>
            {seoMeta && <SEOMeta data={seoMeta} />}
            <div className="border bg-white rounded-xl p-2 md:mx-0 mx-5">
                <div className="flex overflow-x-auto gap-2 hideScrollbar" ref={containerRef}>
                    {teamsIsLoading && <h4 className="p-2"></h4>}
                    {teamData?.teams?.filter(team => team.title !== 'TBA').map((team, index) => (
                        <p
                            key={index}
                            data-testid={team.team_id}
                            className={`text-center text-[10px] md:text-[14px] font-bold p-2 cursor-pointer flex justify-between items-center ${selectedTeamTitle === team.title ? 'bg-[#3A32D1] text-white rounded-xl' : ''}`}
                            onClick={() => handleTeamClick(team.title, team.team_id)}
                        >
                            {team.short_name}
                        </p>
                    ))}
                </div>
            </div>
            <div className="md:mt-2 bg-white md:rounded-2xl rounded-xl border md:mx-0 mx-5 mt-5">
                <div className="">
                    <div className='px-5 md:py-8 py-4'>
                        <div className={`md:min-h-[18px] md:min-w-[180px] flex items-center min-w-[50px]`}>
                            <span className="custom-border-left mr-3 md:mr-6"></span>
                            <h1 className='text-[16px] md:text-2xl'>{selectedTeamTitle}</h1>
                            <span className="custom-border-right ml-3 md:ml-6"></span>
                        </div>
                    </div>
                    <div className="col-span-3 md:ml-10">
                        <div className="min-h-96">
                            {selectedTeamId && !playersError && !loadingPlayers && playerData?.bat &&
                                <>
                                    {/* BATTERS */}
                                    <div className="md:text-[22px] md:border-none border-[#DAE6F8] text-xs md:bg-[#FFFFFF] bg-[#E8F1FF] md:pt-3 px-4 py-1 md:text-start text-center md:pb-[24px] md:font-bold font-medium md:px-0">BATTERS</div>
                                    {playerData?.bat?.length > 0 ? (
                                        <div className="grid grid-cols-2">
                                            {renderPlayers(playerData?.bat)}
                                        </div>
                                    ) : (
                                        <p className="text-gray-600">No batters data available</p>
                                    )}

                                    {/* ALL ROUNDERS */}
                                    <div className="md:text-[22px] md:border-none border-[#DAE6F8] text-xs md:bg-[#FFFFFF] bg-[#E8F1FF] md:pt-3 px-4 py-1 md:text-start text-center md:pb-[24px] md:font-bold font-medium md:px-0">ALL ROUNDERS</div>
                                    {playerData?.all?.length > 0 ? (
                                        <div className="grid grid-cols-2">
                                            {renderPlayers(playerData?.all)}
                                        </div>
                                    ) : (
                                        <p className="text-gray-600">No all rounders data available</p>
                                    )}

                                    {/* WICKET KEEPERS */}
                                    <div className="md:text-[22px] md:border-none border-[#DAE6F8] text-xs md:bg-[#FFFFFF] bg-[#E8F1FF] md:pt-3 px-4 py-1 md:text-start text-center md:pb-[24px] md:font-bold font-medium md:px-0">WICKET KEEPERS</div>
                                    {playerData?.wk?.length > 0 ? (
                                        <div className="grid grid-cols-2">
                                            {renderPlayers(playerData?.wk)}
                                        </div>
                                    ) : (
                                        <p className="text-gray-600">No wicket keepers data available</p>
                                    )}

                                    {/* BOWLERS */}
                                    <div className="md:text-[22px] md:border-none border-[#DAE6F8] text-xs md:bg-[#FFFFFF] bg-[#E8F1FF] md:pt-3 px-4 py-1 md:text-start text-center md:pb-[24px] md:font-bold font-medium md:px-0">BOWLERS</div>
                                    {playerData?.bowl?.length > 0 ? (
                                        <div className="grid grid-cols-2">
                                            {renderPlayers(playerData?.bowl)}
                                        </div>
                                    ) : (
                                        <p className="text-gray-600 mb-4">No bowlers data available</p>
                                    )}
                                </>}
                            {playersError &&
                                (
                                    <div className="text-red-500 mb-4 p-8"></div>
                                )
                            }
                            {loadingPlayers && <h4 className="p-2"></h4>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SeriesTeamsV2;